import { ProTable } from "@ant-design/pro-components";
import { Button, Space, Tooltip, Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import OutForm from "./OutForm";
import { getContractChainList, getInOutPageList } from "../api/biz";
import { useAccount, useConnect } from "wagmi";
import { injected } from "wagmi/connectors";
import { web3Config } from "../config/web3-setup";
import { truncateString } from "../utils";

const { Paragraph } = Typography;

export default function OutPage() {
  const [outVisible, setOutVisible] = useState(false);
  const { isConnected } = useAccount();
  const { connect } = useConnect(web3Config);

  const columns = useMemo(
    () => [
      {
        title: "用户",
        dataIndex: "keywords",
        hideInDescriptions: true,
        hideInTable: true,
      },
      {
        title: "序号",
        dataIndex: "index",
        valueType: "index",
        fixed: "left",
        width: 80,
      },
      {
        title: "链",
        dataIndex: "chainId",
        valueType: "select",
        request: getContractChainList,
      },
      {
        title: "用户昵称",
        dataIndex: ["user", "nickname"],
        search: false,
      },
      {
        title: "用户地址",
        dataIndex: ["user", "address"],
        render: (_, record) => (
          <Tooltip placement="top" title={record.user.address}>
            <Paragraph copyable={{ text: record.user.address }}>
              {truncateString(record.user.address)}
            </Paragraph>
          </Tooltip>
        ),
        search: false,
      },
      {
        title: "提币数量",
        dataIndex: "amount",
        search: false,
      },
      {
        title: "交易hash",
        dataIndex: "trxHash",
        render: (_, record) => (
          <Tooltip placement="top" title={record.trxHash}>
            <Paragraph copyable={{ text: record.trxHash }}>
              {truncateString(record.trxHash)}
            </Paragraph>
          </Tooltip>
        ),
        search: false,
      },
      {
        title: "提币时间",
        dataIndex: "createDatetime",
        valueType: "dateTime",
        search: false,
      },
    ],
    []
  );

  const handleAdd = useCallback(() => {
    if (isConnected) {
      setOutVisible(true);
    } else {
      connect({ connector: injected() });
    }
  }, [connect, isConnected]);

  return (
    <>
      <ProTable
        columns={columns}
        request={getInOutPageList}
        params={{
          type: "1",
        }}
        search={{
          defaultCollapsed: false,
          labelWidth: 120,
        }}
        toolBarRender={() => [
          <Space>
            <Button onClick={handleAdd} key="/add" type="primary">
              提币
            </Button>
          </Space>,
        ]}
      />
      <OutForm visible={outVisible} onVisibleChange={setOutVisible} />
    </>
  );
}
