import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { LoginForm, ProFormText } from "@ant-design/pro-components";
import { Col, Row } from "antd";
import { useCallback } from "react";
import "./index.css";
import fetch from "../../utils/fetch";
import { setToken } from "../../utils";

export default function Login({ afterLogin }) {
  const handleSubmit = useCallback(
    async (values) => {
      try {
        const result = await fetch("/api/core/v1/user/login/sys", {
          ...values,
          clientType: "web",
          userKind: "SYS",
        });
        setToken(result.token);
        afterLogin();
      } catch (error) {}
    },
    [afterLogin]
  );

  return (
    <div className="login-container">
      <div className="login-content">
        <Row>
          <Col span={12} className="left-box">
            <div className="left-bg" />
          </Col>
          <Col span={12} className="right-box">
            <LoginForm
              title="欢迎登录"
              subTitle="Fnxai管理后台"
              onFinish={async (values) => {
                await handleSubmit(values);
              }}
            >
              <ProFormText
                name="loginName"
                fieldProps={{
                  size: "large",
                  prefix: <UserOutlined rev={undefined} />,
                }}
                placeholder="请输入用户名"
                rules={[
                  {
                    required: true,
                    message: "请输入用户名",
                  },
                ]}
              />
              <ProFormText.Password
                name="loginPwd"
                fieldProps={{
                  size: "large",
                  prefix: <LockOutlined rev={undefined} />,
                }}
                placeholder="请输入密码"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              />
            </LoginForm>
          </Col>
        </Row>
      </div>
    </div>
  );
}
