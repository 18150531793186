import { message } from "antd";

export function handleWeb3Error(error) {
  let msg = error.shortMessage || error.message;
  if (/User rejected the request/i.test(msg)) {
    return;
  }
  const errArray = /reason:(.*)$/.exec(msg.replace(/(\r\n|\n|\r)/gm, ""));
  if (errArray && errArray.length) {
    message.error(errArray[1]);
  } else {
    message.error(msg);
  }
}

export function getToken() {
  return localStorage.getItem("token") || "";
}
export function setToken(token) {
  localStorage.setItem("token", token);
}

// 删除用户登录信息
export function clearUser(reload = true) {
  sessionStorage.clear();
  localStorage.removeItem("token");
  if (reload) {
    window.location.reload();
  }
}

export function getUrlParams(url = "") {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};
  let match;
  while ((match = regex.exec(url))) {
    params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
  }
  return params;
}

export function isGreater(num1, num2) {
  if (num1.length > num2.length) {
    return true;
  }
  return num1 > num2;
}

/// 文字中间加省略号
export function truncateString(str, stLength = 6, endLength = 4) {
  if (!str) {
    return "-";
  }
  const start = str.slice(0, stLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}
