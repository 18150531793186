import { useCallback, useEffect, useRef } from "react";
import {
  ModalForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import {
  readContract,
  switchChain,
  waitForTransactionReceipt,
  writeContract,
} from "@wagmi/core";
import { injected } from "wagmi/connectors";
import { message } from "antd";
import { useAccount, useConnect } from "wagmi";
import {
  CHAINS,
  CHAINS_NAMES_MAP,
  ContractAbi,
  StakeContractAddress,
  TokenContractAddress,
  decimalsSuffix,
} from "../config";
import { web3Config } from "../config/web3-setup";
import { handleWeb3Error, isGreater } from "../utils";
import { requiredRule } from "../utils/rule";
import fetch from "../utils/fetch";

export default function InForm({ visible, onVisibleChange, onFinish }) {
  const { address, chainId, isConnected } = useAccount();
  const { connect } = useConnect(web3Config);

  const formRef = useRef();

  useEffect(() => {
    if (visible && formRef.current) {
      formRef.current.resetFields();
    }
  }, [visible]);

  const handleFinish = useCallback(
    async (values) => {
      if (!isConnected) {
        connect({ connector: injected() });
        return;
      }
      try {
        // eslint-disable-next-line eqeqeq
        if (chainId != values.chainId) {
          await switchChain(web3Config, {
            chainId: Number(values.chainId),
          });
        }
        // eslint-disable-next-line eqeqeq
        if (values.chainId != CHAINS[0].id) {
          const allowance = await readContract(web3Config, {
            address: TokenContractAddress[values.chainId],
            abi: ContractAbi[values.chainId],
            functionName: "allowance",
            args: [address, StakeContractAddress[values.chainId]],
          });
          // 质押数量 > allowance
          if (isGreater(values.amount + decimalsSuffix, allowance.toString())) {
            const hash = await writeContract(web3Config, {
              address: TokenContractAddress[values.chainId],
              abi: ContractAbi[values.chainId],
              functionName: "approve",
              args: [
                StakeContractAddress[values.chainId],
                "1157920892373161954235709850086879078532699846656405640394575840079131",
              ],
            });
            const result = await waitForTransactionReceipt(web3Config, {
              chainId: Number(values.chainId),
              hash,
            });
            if (result.status !== "success") {
              message.error("操作失败");
              return;
            }
          }
        }
        await fetch("/api/core/v1/product_order/check_oss", {
          address,
          ...values,
        });
        await writeContract(web3Config, {
          functionName: "stake",
          address: StakeContractAddress[values.chainId],
          abi: ContractAbi[values.chainId],
          args: [values.amount + decimalsSuffix],
        });
      } catch (error) {
        handleWeb3Error(error);
        return;
      }
      message.success("操作成功，需要等待区块确认后才可以看到新的充币数据", 5);
      onVisibleChange(false);
      if (onFinish) {
        onFinish();
      }
    },
    [address, chainId, connect, isConnected, onFinish, onVisibleChange]
  );

  return (
    <ModalForm
      title="充币"
      open={visible}
      onOpenChange={onVisibleChange}
      width={400}
      formRef={formRef}
      onFinish={handleFinish}
      initialValues={{
        chainId: chainId && chainId.toString(),
      }}
    >
      <ProFormSelect
        name="chainId"
        label="链"
        valueEnum={CHAINS_NAMES_MAP}
        rules={[requiredRule]}
      />
      <ProFormDigit
        name="amount"
        label="充币数量"
        rules={[requiredRule]}
        fieldProps={{
          precision: 0,
        }}
      />
    </ModalForm>
  );
}
