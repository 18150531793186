import { PageContainer } from "@ant-design/pro-components";
import "./App.css";
import { useCallback, useEffect, useState } from "react";
import { getToken, getUrlParams, setToken } from "./utils";
import Login from "./components/Login";
import InPage from "./components/InPage";
import OutPage from "./components/OutPage";

function App() {
  const [isLogin, setIsLogin] = useState(false);

  const [tabKey, setTabKey] = useState("0");

  useEffect(() => {
    const token = getUrlParams(window.location).t;
    const tabIndex = getUrlParams(window.location).tb;
    if (token) {
      setToken(token);
      let reloadUrl = "/";
      if (tabIndex) {
        reloadUrl = "/?tb=" + tabIndex;
      }
      if (window.location.replace) {
        window.location.replace(reloadUrl);
      } else {
        window.location.href = "/?tb=" + tabIndex;
      }
    }
    if (tabIndex) {
      setTabKey(tabIndex);
    }
    setIsLogin(getToken());
  }, []);
  const afterLogin = useCallback(() => {
    setIsLogin(getToken());
  }, []);

  return isLogin ? (
    <div
      style={{
        background: "#F5F7FA",
      }}
    >
      <PageContainer
        header={{
          title: "Fnxai管理系统",
          extra: [<w3m-button key="/connect" />],
        }}
        tabActiveKey={tabKey}
        onTabChange={setTabKey}
        tabList={[
          {
            tab: "充币",
            key: "0",
          },
          {
            tab: "提币",
            key: "1",
          },
        ]}
      >
        {tabKey === "0" ? <InPage /> : <OutPage />}
      </PageContainer>
    </div>
  ) : (
    <Login afterLogin={afterLogin} />
  );
}

export default App;
