import {
  arbitrumSepolia as arbitrum,
  sepolia as mainnet,
  bscTestnet as bsc,
} from "viem/chains";
// import { arbitrum, mainnet, bsc } from "viem/chains";
import mainnetAbi from "./mainnet_abi";
import arbitrumAbi from "./arbitrum_abi";
import bnbAbi from "./bnb_abi";

export const ContractAbi = {
  [mainnet.id]: mainnetAbi,
  [arbitrum.id]: arbitrumAbi,
  [bsc.id]: bnbAbi,
};

// dev
export const TokenContractAddress = {
  [mainnet.id]: "0xffF55f1D87D74adB72f31ecbE6bb2b7824498FFF",
  [arbitrum.id]: "0xCDa6b47f28b3C2eF8FD71eAFA05F7ae45C5e7666",
  [bsc.id]: "0xffF55f1D87D74adB72f31ecbE6bb2b7824498FFF",
};

// stake contract
export const StakeContractAddress = {
  [mainnet.id]: "0xffF55f1D87D74adB72f31ecbE6bb2b7824498FFF", // stake & token contract
  [arbitrum.id]: "0xfff55f1d87d74adb72f31ecbe6bb2b7824498fff",
  [bsc.id]: "0x2Ffbbe457C70868B8a06e9db5E35cff6b07A2515",
};

// online
// export const TokenContractAddress = {
//   [mainnet.id]: '0x939069722D568B5498CcBA4356E800EaefEFD2a5',
//   [arbitrum.id]: '0x3088E120b220E67a2e092F5dA8cdf02ea0170F6A',
//   [bsc.id]: '0xD26889f63094Ba5A9d32666CdF5Ba381acfad6A6'
// }
// // stake contract
// export const StakeContractAddress = {
//   [mainnet.id]: '0x939069722D568B5498CcBA4356E800EaefEFD2a5', // stake & token contract
//   [arbitrum.id]: '0x939069722D568B5498CcBA4356E800EaefEFD2a5',
//   [bsc.id]: '0x939069722D568B5498CcBA4356E800EaefEFD2a5'
// }

export const CHAINS = [mainnet, arbitrum, bsc];
export const CHAINS_NAMES_MAP = {
  [mainnet.id]: "ETHEREUM",
  [arbitrum.id]: "ARBITRUM",
  [bsc.id]: "BNB",
};

export const decimals = 1000000000000000000; // 10^18
export const decimalsSuffix = "000000000000000000";
