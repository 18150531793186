import { useCallback, useEffect, useRef } from "react";
import {
  ModalForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { switchChain, writeContract } from "@wagmi/core";
import { injected } from "wagmi/connectors";
import { message } from "antd";
import { useAccount, useConnect } from "wagmi";
import { CHAINS_NAMES_MAP, ContractAbi, StakeContractAddress } from "../config";
import { web3Config } from "../config/web3-setup";
import { handleWeb3Error } from "../utils";
import { requiredRule } from "../utils/rule";
import fetch from "../utils/fetch";

export default function OutForm({ visible, onVisibleChange, onFinish }) {
  const { address, chainId, isConnected } = useAccount();
  const { connect } = useConnect(web3Config);

  const formRef = useRef();

  useEffect(() => {
    if (visible && formRef.current) {
      formRef.current.resetFields();
    }
  }, [visible]);

  const handleFinish = useCallback(
    async (values) => {
      if (!isConnected) {
        connect({ connector: injected() });
        return;
      }
      try {
        // eslint-disable-next-line eqeqeq
        if (chainId != values.chainId) {
          await switchChain(web3Config, {
            chainId: Number(values.chainId),
          });
        }
        const res = await fetch(
          "/api/core/v1/account_income/get_redemption_oss",
          {
            address,
            ...values,
          }
        );
        await writeContract(web3Config, {
          functionName: "redeem",
          address: StakeContractAddress[values.chainId],
          abi: ContractAbi[values.chainId],
          args: [res.amount, res.nonce, res.timestamp, res.message],
        });
      } catch (error) {
        handleWeb3Error(error);
        return;
      }
      message.success("操作成功，需要等待区块确认后才可以看到新的提币数据", 5);
      onVisibleChange(false);
      if (onFinish) {
        onFinish();
      }
    },
    [address, chainId, connect, isConnected, onFinish, onVisibleChange]
  );

  return (
    <ModalForm
      title="提币"
      open={visible}
      onOpenChange={onVisibleChange}
      width={400}
      formRef={formRef}
      onFinish={handleFinish}
      initialValues={{
        chainId: chainId && chainId.toString(),
      }}
    >
      <ProFormSelect
        name="chainId"
        label="链"
        valueEnum={CHAINS_NAMES_MAP}
        rules={[requiredRule]}
      />
      <ProFormDigit
        name="number"
        label="提币数量"
        rules={[requiredRule]}
        fieldProps={{
          precision: 0,
        }}
      />
    </ModalForm>
  );
}
