import { QueryClient } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import {
  arbitrumSepolia as arbitrum,
  sepolia as mainnet,
  bscTestnet as bsc,
} from "viem/chains";
// import { arbitrum, mainnet, bsc } from "viem/chains";

// 0. Setup queryClient
export const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
export const projectId = "ca95e6e406851a45ae7299877bb36b36";

// 2. Create wagmiConfig
export const metadata = {
  name: "FNXAI",
  description: "FNXAI",
  url: "https://app.finanx.ai",
  icons: ["https://app.finanx.ai/favicon.ico"],
};

const chains = [mainnet, arbitrum, bsc];
export const web3Config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: web3Config,
  projectId,
  //   enableAnalytics: true, // Optional - defaults to your Cloud configuration
  //   enableOnramp: true, // Optional - false as default
});
