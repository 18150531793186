import { message } from "antd";
import axios from "axios";
import { clearUser, getToken } from "./index";

const ERR_OK = "200";
const ERR_TIME_OUT = "4";
const ERR_TOKEN = "300000";
const EXPIRE_TOKEN = "300001";
const NULL_TOKEN = "300002";
const INSUFFICIENT_AUTH = "300003";

export default function fetch(url, params) {
  const fetchUrl = url;
  const data = JSON.stringify(params || {});

  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: getToken(),
    },
    url: fetchUrl,
    data: data,
    timeout: 0,
  }).then((res) => {
    res = res.data;
    const outList = [
      ERR_TIME_OUT,
      ERR_TOKEN,
      EXPIRE_TOKEN,
      NULL_TOKEN,
      INSUFFICIENT_AUTH,
      "A50004",
    ];
    if (outList.includes(res.errorCode)) {
      clearUser();
      return Promise.reject("logout");
    }
    if (res.code !== ERR_OK) {
      const errMsg = res.errorInfo || res.errorMsg || "error";
      message.error(errMsg);
      return Promise.reject(errMsg);
    }
    return Promise.resolve(res.data);
  });
}
