import fetch from "../utils/fetch";

/** 获取分页列表 */
export async function getContractChainList() {
  return fetch("/api/core/v1/contract_chain/list").then((list) => {
    return list.map((item) => ({
      label: item.name,
      value: item.chainId,
    }));
  });
}

/** 获取充提币分页列表 */
export async function getInOutPageList({ current, ...restParams }) {
  return fetch("/api/core/v1/chain_transaction_record/page", {
    pageNum: current,
    ...restParams,
  })
    .then((res) => {
      return {
        data: res.list,
        total: res.total,
        success: true,
      };
    })
    .catch(() => ({
      data: [],
      total: 0,
      success: false,
    }));
}
